.staticMenu{
    position: -webkit-sticky;
    position: fixed;
    top: 5vh;
    left:0;
    height: '95vh';
}

/* @media (max-width: 1000px){
    .staticMenu{
        position:relative;
        height: 20% !important;
        margin-bottom: 10px;
    }
} */
.css-5xe99f-MuiLinearProgress-bar1{
background-color: rgb(28, 28, 28)!important;
z-index: 2;
}