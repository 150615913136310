/* FORMULARIO */

.input-stl {
  padding-left: 1em;
  width: 100%;
  height: 30px;
}

.input-stlClear {
  padding-left: 1em;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid var(--color-input-border);
  border-top: 0;
  border-right: 0;
  border-left: 0;
}
.inputBig-stl {
  padding-left: 1em;
  width: 378px;
  height: 106px;
  white-space: initial;
}

.iconwidth {
  width: 25px;
  height: 25px;
}

.h4-stl-form {
  font-style: normal;
  font-weight: normal;
  font-size: var(--size-head4);
  line-height: 17px;

  color: var(--color-input-text);
}
