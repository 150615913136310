* {
  box-sizing: border-box;
}

.h4-stl {
  font-size: var(--size-global1);
}

.h5-stl {
  font-size: var(--size-global2);
}

.header-stl {
  padding: 0;
  position: fixed;
  top: 0;
  height: 5vh;
  width: 100%;
  z-index: 905;
  background-color: var(--color-nav-bg);
  color: var(--color-white);
}
.header-pad {
  margin-top: 5vh !important;
}
.nav1 {
  height: 95vh;
}
a {
  color: #007bff;
}
.Right {
  text-align: right;
}

.Center {
  text-align: center;
}

.globalArrow {
  content: "\25BA";
  padding: 0 0.4rem;
  color: #222;
}

.nav-item {
  cursor: pointer !important;
}
.accordion-button {
  background-color: rgb(28, 28, 28) !important;
  color: var(--color-white);
}
#head2 .accordion-button {
  background-color: var(--color-nav-option-bg) !important;
  color: var(--color-white);
  padding-left: 13px;
}
.accordion-button:hover {
  background-color: var(--color-nav-active) !important;
  padding-left: 13px;
}
#head2 .accordion-button:hover {
  background-color: var(--color-nav-active) !important;
  padding-left: 13px;
}
#head2 .accordion-button:focus {
  background-color: var(--color-nav-active) !important;
}
.nav-item:hover {
  background-color: var(--color-nav-active) !important;
}

.accordion-button:not(.collapsed) {
  color: var(--color-white);
  cursor: pointer !important;
}

.languaje_brand {
  margin: 0 5em 0 0;
}
.languaje_brand:hover {
  border-bottom: solid 1px rgb(184, 49, 49);
  cursor: pointer;
}

.accordion-button:focus {
  background-color: var(--color-nav-active) !important;
  border-color: var(--color-white) !important;
  box-shadow: none;
}
.accordion-button.active {
  background-color: var(--color-nav-active) !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../images/WhiteArrow.png");
  background-size: 8px;
  transform: rotate(90deg) translateX(8px) translateY(7px);
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important; */
}
.accordion-button::after {
  background-image: url("../images/WhiteArrow.png");
  background-size: 8px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--color-nav-active);
}

.numberUsers {
  background: var(--color-main-text);
  color: var(--color-white);
  border-radius: 20px;
  padding: 4px 8px;
  font-size: var(--size-head5);
  margin-left: 5px;
}

.menuMain:hover {
  background-color: var(--color-nav-active);
  border-radius: 10px;
}
.dropdownCircle-stl {
  color: var(--color-input-text);
  border-radius: 2px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: var(--size-head3);
  line-height: 16px;
  max-width: 330px;
  height: 30px;
  border: 1px solid #ced4da;
}

.containerBox {
  background-color: var(--color-white);
  border-radius: 10px;
}

.containerBoxHead {
  background-color: var(--color-white);
  border-radius: 10px;
  border: 2px solid var(--color-input-border);
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
}

.sectionBoxTop {
  border-bottom: 2px solid var(--color-border);
}

.sectionBoxBottom {
  border-top: 2px solid var(--color-border);
}
.imgSearch {
  padding: 10px 15px;
  border-radius: 5px;
}
.imgSearch:hover {
  background-color: rgba(222, 222, 222);
  cursor: pointer;
}
.grayBackgr {
  padding: 10px 15px;
  border-radius: 5px;
}

.grayBackgr:hover {
  background-color: rgba(222, 222, 222);
  cursor: pointer;
}
.filterBox {
  border-radius: 0;
  border: solid 1px var(--color-border);
  box-shadow: 2px 2px 6px var(--color-border);
}
.modal-90w {
  width: 90vw !important;
  max-width: none;
}
/* Styles Tabs */

.tabs-info .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--color-white);
  color: var(--color-main-text);
}

.nav-link:hover {
  color: var(--color-main-text);
  background-color: transparent;
}

.tabs-info .nav-link {
  color: var(--color-main-text);
  border-bottom: 1px solid white;
}

.row {
  margin-right: 0;
  margin-left: 0;
}
.bg-gray {
  background-color: rgb(250, 250, 250);
}

.boxgray:hover {
  background-color: var(--color-required);
  padding: 5px;
  cursor: pointer;
}

.cssanimation,
.cssanimation {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.cssanimation span {
  display: inline-block;
}

.fadeInTop {
  animation-name: fadeInTop;
}
@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fadeInBottom {
  animation-name: fadeInBottom;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
  }
}

@media (max-height: 850px) {
  .header-stl {
    height: 6.5vh;
  }
  .header-pad {
    margin-top: 6.5vh !important;
  }
  .nav1 {
    height: 95vh;
  }
}
@media (max-height: 640px) {
  .header-stl {
    height: 8vh;
  }
  .header-pad {
    margin-top: 8vh !important;
  }
  .nav1 {
    height: 92vh;
  }
}
@media (max-height: 450px) {
  .header-stl {
    height: 11vh;
  }
  .header-pad {
    margin-top: 11vh !important;
  }
  .nav1 {
    height: 89vh;
  }
}
