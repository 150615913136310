.loader {
  position: fixed !important;
  top: 49%;
  left: 49%;
  width: 50px;
  height: 50px;
  z-index: 10;
  opacity: 1;
}

#loaderFullWith {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgb(0, 0, 0, 0.3);
}
